<template>
  <v-container fluid style="padding: 0 0 0 0">
    <v-container fluid>
      <div class="row justify-center align-content-center"  style="background: #E84C3D;" :style="customStyle">
        <div class="col-lg-5">
          <div class="custom-title">
            Creartly: A Platform For Creatives to connect and grow Their Businesses.
          </div>
          <div class="custom-text">
            Creartly is an app that allows a new way for creatives to hire other creatives for help on a job or gig.
            It’s a great platform for photographers/videographers who are seeking to hire or contract an associate
            and/or second shooter job. This allows for experienced/entry level creatives to make passive income as well
            as find job opportunities for those seeking experience in a specific creative field.
          </div>
        </div>
        <div class="col-lg-4" style="min-height: 300px">
          <v-card class="fill-height">
            <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/8J_7mA8LSk4"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
            ></iframe>
          </v-card>

        </div>
      </div>
    </v-container>
<!--  BEGIN FORM   -->
    <v-container fluid style="background-color: #f7f8f9;">
      <v-container >
        <v-card class="py-4" elevation="2" :style="$vuetify.breakpoint.lgAndUp ? formMarginStyle : ''" style="border-radius: 20px;">
          <v-form ref="form">
            <v-container>
              <v-row v-if="submitted" justify="center" align-content="center" style="min-height: 400px;">
                <v-col cols="12" lg="10" >
                  <div class="form-title text-center">
                    <p>Thank you for your interest in Creartly.</p>
                    <p>We've sent an email confirming your interest.</p>
                    <p>If you do not not see it, check your spam folder or promotions tab.</p>
                  </div>
                </v-col>
              </v-row>
              <v-row v-else justify="center" align-content="center" style="min-height: 400px;">
                <v-col cols="10" class="row justify-center">
                  <div class="col-lg-4 col-10">
                    <v-img
                        :src="require('../assets/creartly_logo_black.png')"
                    />
                  </div>

                </v-col>
                <v-col cols="10">
                  <div class="form-title text-center mb-4 text-capitalize">
                    Fill out this form to get notified when we launch the app.
                  </div>
                </v-col>
                <v-col lg="5" cols="12">
                  <v-text-field v-model="sendInBlueForm.attributes.FIRSTNAME" :rules="[rules.required]" outlined label="First Name"></v-text-field>
                </v-col>
                <v-col lg="5" cols="12">
                  <v-text-field v-model="sendInBlueForm.attributes.WEBSITE" outlined label="Business Website Or Social Media (OPTIONAL)"></v-text-field>
                </v-col>
                <v-col lg="5" cols="12">
                  <v-text-field v-model="sendInBlueForm.email" :rules="[rules.email, rules.required]" outlined label="Email"></v-text-field>
                </v-col>
                <v-col lg="5" cols="12">
                  <v-select v-model="sendInBlueForm.attributes.TYPEOFCREATIVE" :rules="[rules.required]" :items="creativeOptions" item-text="label" item-value="value" outlined label="Select Type Of Creative"></v-select>
                </v-col>
                <v-col v-if="sendInBlueForm.attributes.TYPEOFCREATIVE === 'OTHER'" lg="5" cols="12">
                  <v-text-field v-model="sendInBlueForm.attributes.OTHERCREATIVE" outlined placeholder="Please tell us what type of creative"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-row justify="center">
                    <v-col cols="9" lg="4">
                      <v-btn
                          rounded
                          outlined
                          color="#E84C3D"
                          style="height: 50px; width: 100%; font-family: Poppins-Bold, sans-serif; font-size: 20px;"
                          @click="saveDataSendInBlue"
                          :loading="submitting"
                      >
                        Submit
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-container>
    </v-container>
<!-- END FORM   -->
    <v-container fluid style="background-color: #f7f8f9; border: 5px solid white" class="pb-2">
      <v-container>
        <v-row style="min-height: 550px" justify="center" align-content="center">
          <v-col cols="11" lg="4">
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-img :src="require('../assets/creartly_save_hire.png')" :width="150"/>
              </v-col>

              <v-col cols="12" class="row align-content-center">
                <div class="col-12 info-title text-center">
                  Post And Respond To Jobs
                </div>
                <div class="col-12 text-center">
                  Creartly allows creatives to find full replacements or a helping hand for a job, event or gig. Browse jobs by other creatives or post yours
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer v-if="$vuetify.breakpoint.lgAndUp" />
          <v-col cols="11" lg="4">
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-img :src="require('../assets/creartly_groups_cmp.png')" :width="150"/>
              </v-col>

              <v-col cols="12" class="row align-content-center">
                <div class="col-12 info-title text-center">
                  Connect With Other Creatives
                </div>
                <div class="col-12 text-center">
                  Connect and build your network of creatives. Use your network to scale your business.
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>


    </v-container>
  </v-container>

</template>

<script>
// eslint-disable-next-line no-unused-vars
import { Timestamp } from "firebase/firestore";
export default {
  name: "MainView",
  data() {
    return {
      valid: '',
      form: {
        first_name: '',
        website: '',
        email: '',
        type_of_creative: '',
        other_creative: ''
      },
      sendInBlueForm: {
        attributes: {
          FIRSTNAME: '',
          WEBSITE: '',
          TYPEOFCREATIVE: '',
          OTHERCREATIVE: ''
        },
        email: '',
      },
      submitting: false,
      submitted: false,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  computed: {
    height() {
      return this.$vuetify.breakpoint.lgAndUp ? 700 : 1000
    },
    customStyle(){
      return `height: ${this.height}px`
    },
    formMarginStyle() {
      return 'margin-top: -100px;'
    },
    creativeOptions() {
      return [
        {
          label: 'Photographer',
          compName: 'Photographer',
          value: 'PHOTOGRAPHER'
        },
        {
          label: 'Videographer',
          compName: 'Videographer',
          value: 'VIDEOGRAPHER'
        },
        {
          label: 'Photo Editor',
          compName: 'PhotoEditor',
          value: 'PHOTOEDITOR'
        },
        {
          label: 'Other',
          compName: 'Other',
          value: 'OTHER'
        },
      ]
    }
  },
  methods: {
    async saveDataSendInBlue() {
      if (!(await this.$refs.form.validate())) {
        return
      }
      this.submitting = true

      const docData = {
        email: this.sendInBlueForm.email.toLowerCase(),
        attributes: {
          ...this.sendInBlueForm.attributes,
          // created: Timestamp.fromDate(new Date())
        }
      }
      docData.attributes.FIRSTNAME = docData.attributes.FIRSTNAME[0].toUpperCase() + docData.attributes.FIRSTNAME.substring(1).toLowerCase()
      const form_data = new FormData();
      form_data.append('email', docData.email.toLowerCase())
      form_data.append('attributes', docData.attributes)

      const axios = require('axios').default;
      const url = process.env.NODE_ENV !== 'production' ? 'http://localhost:8090/creartly-9e2e8/us-central1/emailSender' : 'https://email-sender-wdrzx3lgza-uc.a.run.app'
      // todo: maybe wait for success or failure?
      axios({
        method: "post",
        url: url,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: `data=${encodeURIComponent(JSON.stringify(docData))}`
      })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      // set form to blank
      this.sendInBlueForm.email = ''
      for (const formKey in this.sendInBlueForm.attributes) {
        this.sendInBlueForm.attributes[formKey] = ''
      }
      this.submitting = false
      this.submitted = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .custom-title {
    font-size: 28px !important;
    text-align: center;
    line-height: 40px !important;
  }
  .custom-text {
    text-align: center;
  }
}
.info-title {
  font-family: Poppins-Bold, sans-serif;
  font-size: 24px;
  line-height: 25px;
}
.custom-title {
  font-family: Poppins-Bold, sans-serif;
  color: #FFFFFF;
  font-size: 48px;
  font-weight: 700;
  line-height: 65px;
  margin-bottom: 28px;
  text-transform: capitalize;
}

.form-title {
  font-family: Poppins-Bold, sans-serif;
  color: #E84C3D;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  text-transform: capitalize;
}
.custom-text {
  font-family: Poppins-Regular, sans-serif;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 45px;
}
.bottom-bg-div {
  background: #FFFFFF;
  //height: 250px;
}


</style>
